<template>
  <el-dialog title="点位查看" width="1200px" :visible="selectPointShow" :close-on-click-modal="false" @close="closeDialog">
    <div class="content">
      <div class="mapBlock" id="map" ref="map"></div>
      <div class="deviceBlock">
        <p class="title">巡查对象：</p>
        <div class="content">
          <template v-if="facilityList && facilityList.length > 0">
            <el-tree
              :data="facilityList"
              :props="defaultProps"
              default-expand-all>
              <span slot-scope="{ node, data }">
                <span v-if="data.facilityName">{{ node.label }}</span>
                <template v-else>
                  <span>{{ data.facilityTypeName }}&nbsp;
                    <span class="c-default">({{data.facilityList.length}})</span>
                  </span>
                </template>
              </span>
            </el-tree>
          </template>
          <template v-else>
            <p class="nodata">暂无</p>
          </template>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey, iconUrl } from '../../../utils/config'
import { drawPolygon } from '../../../utils/formatData'
export default {
  name: 'PointSelectDetail',
  props: {
    projectId: String,
    selectPointShow: Boolean,
    facilityList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  watch: {
    selectPointShow (val) {
      if (val) {
        this.getProjectLocation()
      }
    }
  },
  computed: {
  },
  data () {
    return {
      defaultProps: {
        children: 'facilityList',
        label: 'facilityName'
      },
      // 已选择设备的经纬度集合
      lngLatList: []
    }
  },
  created () {
    // ...
  },
  methods: {
    getProjectLocation () {
      this.$axios.get(this.$apis.project.selectProjectAreaById, {
        projectId: this.projectId
      }).then((res) => {
        this.$nextTick(() => {
          this.loadMap(res.projectInfo, res.projectLocation)
        })
      })
    },
    loadMap (projectInfo, list) {
      const center = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
      AMapLoader.load({
        key: mapKey,
        version: '2.0',
        plugins: []
      }).then(() => {
        const map = new AMap.Map('map', {
          center: center,
          zoom: 16
        })
        this._map = map
        const style = {
          strokeWeight: 3,
          fillOpacity: 0,
          strokeStyle: 'dashed'
        }
        this.ploygons = drawPolygon(this._map, list || [], style, null)
        this.facilityList.length && this.drawMarker()
      })
    },
    drawMarker () {
      this.facilityList.forEach(item => {
        item.facilityList.forEach(fItem => {
          const lngLat = new AMap.LngLat(Number(fItem.longitude), Number(fItem.latitude))
          const marker = new AMap.Marker({
            icon: new AMap.Icon({
              // 图标的取图地址
              image: iconUrl + fItem.facilityIcon + '_1.png'
            }),
            size: new AMap.Pixel(40, 40),
            offset: new AMap.Pixel(-20, -40),
            position: lngLat
          })
          this._map.add(marker)
        })
      })
      this._map.setFitView(this._map.getAllOverlays('marker'))
    },
    // 关闭
    closeDialog () {
      this.$emit('update:selectPointShow', false)
    }
  }
}
</script>

<style scoped lang="scss">
  .text-center {
    text-align: center;
  }
  .content {
    position: relative;
    width: 100%;
    height: 600px;
    .mapBlock {
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    .deviceBlock {
      position: absolute;
      left: 20px;
      top: 20px;
      z-index: 2;
      width: 300px;
      height: auto;
      background: #FFFFFF;
      box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.1);
      border-radius: 4px;
      .title {
        width: 100%;
        height: 52px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0,0,0,0.85);
        line-height: 22px;
        padding: 15px 24px;
        background: #F6F7FB;
      }
      .content {
        width: 100%;
        height: auto;
        max-height: 508px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
        padding: 16px 20px;
        overflow-y: auto;
      }
    }
    .numBlock {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 190px;
      height: 94px;
      background: #FFFFFF;
      box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.1);
      border-radius: 4px;
      .icon {
        width: 48px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        background: #3592FE;
        border-radius: 50%;
        .global-icon {
          font-size: 24px;
          color: #ffffff;
        }
      }
      .info {
        margin-left: 12px;
        p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 20px;
          &.num {
            font-size: 24px;
            font-weight: 600;
            color: #333333;
            line-height: 34px;
          }
        }
      }
    }
  }
  ::v-deep .tdt-pane>svg path.tdt-interactive {
    pointer-events: none;
}
</style>
