<template>
  <div>
    <el-dialog
        title="巡查对象"
        :visible="isShowObject"
        width='800px'
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal='false'
        @close="onClose">
        <el-table class="global-table" max-height="600px" :data="elementList">
            <el-table-column label="已选巡查对象" show-overflow-tooltip width="200px">
                <template v-slot="{row}">{{row.elementName || row.facilityTypeName}}
                    <span v-if="row.facilityTypeName">({{row.facilityCount}})</span>
                </template>
            </el-table-column>
            <el-table-column label="巡查要素" prop="standardList" min-width="120"></el-table-column>
        </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {
    }
  },
  created () {
    // ...
  },
  components: {
  },
  props: {
    isShowObject: {
      type: Boolean,
      default: false
    },
    elementList: Array
  },
  computed: {},
  watch: {},
  methods: {
    onClose () {
      this.$emit('update:isShowObject', false)
    }
  }
}
</script>

<style scoped>

</style>
