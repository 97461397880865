<template>
  <el-dialog title="巡查线路查看" width="1200px" :visible="selectLineShow" :close-on-click-modal="false" @close="closeDialog">
    <div class="content">
      <div class="mapBlock" id="map" ref="map"></div>
      <div class="deviceBlock">
        <p class="title">巡查对象：</p>
        <div class="ctt">
          <template v-if="elementList && elementList.length > 0">
            <p class="item" v-for="element in elementList" :key="element.elementId">{{element.elementName}}</p>
          </template>
          <template v-else>
            <p class="nodata">暂无</p>
          </template>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '../../../utils/config'
import { drawPolygon } from '../../../utils/formatData'
const patrolSign = require('@/assets/img/patrol/sign.svg')
const patrolNotpassed = require('@/assets/img/patrol/notpassed.svg')
export default {
  name: 'LineSelectDetail',
  props: {
    projectId: String,
    selectLineShow: Boolean,
    elementList: {
      type: Array,
      default: () => {
        return []
      }
    },
    lineList: {
      type: Array,
      default: () => {
        return []
      }
    },
    planeRegisterList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  watch: {
    selectLineShow (val) {
      if (val) {
        this.getProjectLocation()
      }
    }
  },
  data () {
    return {
    }
  },
  created () {
    // ...
  },
  methods: {
    getProjectLocation () {
      this.$axios.get(this.$apis.project.selectProjectAreaById, {
        projectId: this.projectId
      }).then((res) => {
        this.$nextTick(() => {
          this.loadMap(res.projectInfo, res.projectLocation)
        })
      })
    },
    loadMap (projectInfo, list) {
      const center = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
      if (!this._map) {
        AMapLoader.load({
          key: mapKey,
          version: '2.0',
          plugins: ['AMap.MouseTool']
        }).then(() => {
          const map = new AMap.Map('map', {
            center: center,
            zoom: 16
          })
          this._map = map
          this.mapInit(list)
        })
      } else {
        this._map.clearMap()
        this._map.setZoomAndCenter(16, center)
        this.mapInit(list)
      }
    },
    mapInit (list) {
      this.ploygons = drawPolygon(this._map, list || [], { zIndex: 10 }, null)
      if (this.lineList && this.lineList.length > 0) {
        // 巡查路线
        this.drawPatrolPolyline(this.lineList)
        const markers = [{
          ...this.lineList[0]
        }, {
          ...this.lineList[this.lineList.length - 1]
        }]
        const mustPoints = [...this.planeRegisterList]
        this.drawMarkers(markers)
        this.drawMarkers(mustPoints, true)
      }
    },
    drawPatrolPolyline (lineList) {
      const lngLat = lineList.map(item => {
        return new AMap.LngLat(item.longitude, item.latitude)
      })
      // 创建面对象
      const polyline = new AMap.Polyline({
        path: lngLat,
        strokeWeight: 4,
        strokeColor: '#FF9B00',
        strokeOpacity: 1
      })
      this._map && this._map.add(polyline)
      this._patrolPplyline = polyline
      console.log('polyline:', polyline)
    },
    drawMarkers (list, isMustPoint) {
      list.forEach((item) => {
        if (item.longitude && item.latitude) {
          let marker = null
          const lngLat = new AMap.LngLat(Number(item.longitude), Number(item.latitude))
          if (isMustPoint) {
            marker = new AMap.Marker({
              icon: new AMap.Icon({
                // 图标的取图地址
                image: patrolNotpassed
              }),
              size: new AMap.Pixel(44, 49),
              offset: new AMap.Pixel(-22, -38),
              position: lngLat,
              label: {
                direction: 'top',
                content: '必经点'
              }
            })
          } else {
            marker = new AMap.Marker({
              icon: new AMap.Icon({
                // 图标的取图地址
                image: patrolSign
              }),
              size: new AMap.Pixel(24, 24),
              offset: new AMap.Pixel(-12, -24),
              position: lngLat
            })
          }
          this._map && this._map.add(marker)
        }
      })
    },
    // 关闭
    closeDialog () {
      this.$emit('update:selectLineShow', false)
    }
  }
}
</script>

<style scoped lang="scss">
  .content {
    position: relative;
    width: 100%;
    height: 600px;
    .mapBlock {
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    .deviceBlock {
      position: absolute;
      left: 20px;
      top: 20px;
      z-index: 2;
      width: 300px;
      height: auto;
      background: #FFFFFF;
      box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.1);
      border-radius: 4px;
      .title {
        width: 100%;
        height: 52px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0,0,0,0.85);
        line-height: 22px;
        padding: 15px 24px;
        background: #F6F7FB;
      }
      .ctt {
        width: 100%;
        height: auto;
        max-height: 508px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
        padding: 20px 24px;
        overflow-y: auto;
        .item:not(:last-child) {
          padding-bottom: 18px;
        }
      }
    }
  }

  ::v-deep .amap-marker-label {
    border-color: gray;
  }
</style>
