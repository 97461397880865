<template>
  <el-card>
    <div class="title-block">任务信息</div>
    <div class="itemBlock">
      <div class="lineBlock">
        <p class="name">任务名称：</p>
        <p class="value">{{detail.planName || ''}}</p>
      </div>
      <div class="lineBlock">
        <p class="name">所属项目：</p>
        <p class="value">{{detail.projectName || ''}}</p>
      </div>
      <div class="lineBlock">
        <p class="name">任务指派人：</p>
        <p class="value">{{detail.createUserName}}</p>
      </div>
      <div class="lineBlock">
        <p class="name">任务执行人：</p>
        <p class="value">{{detail.executeUserName || ''}}</p>
      </div>
      <div class="lineBlock">
        <p class="name">巡查类型：</p>
        <p class="value">{{[1, 2, 3].includes(detail.planType) ? planTypeList[detail.planType - 1] : ''}}</p>
      </div>
      <div class="lineBlock">
        <p class="name">巡查频次：</p>
        <div class="line-time">
            <span>{{detail.patrolFrequency || 0}}次/{{dateName}}</span>
            <template v-if="detail.planType === 1">&nbsp;&nbsp;( <span v-for="(time, index) in detail.timeList" :key="time.id">{{time.startTime}} 至 {{time.endTime}}
                <template v-if="index !== detail.timeList.length-1">，</template>
            </span>)</template>
        </div>
      </div>
      <div class="lineBlock">
          <p class="name">巡查方式：</p>
          <p class="patrol-type" @click="selectPatrolType(detail.patrolMode)">{{[1, 2].includes(detail.patrolMode) ? patrolModeList[detail.patrolMode - 1] : ''}}</p>
      </div>
      <div class="lineBlock">
        <p class="name">允许距离偏差：</p>
        <p class="value">{{detail.offsetDistance || ''}}米</p>
      </div>
      <div class="lineBlock">
        <p class="name">创建时间：</p>
        <p class="value">{{detail.ctime || ''}}</p>
      </div>
      <div class="lineBlock">
        <p class="name">生效方式：</p>
        <p class="value">{{detail.generateType === 1 ? '次' + dateName + '生效' : detail.generateType === 2 ? '立即生效' : ''}}</p>
      </div>
      <div class="lineBlock" v-if="detail.facilityList">
        <p class="name">巡查对象：</p>
        <p class="pointDetail" @click="isShowObject = true">已选({{listHandler(elementList)}})</p>
        <!-- <el-tooltip popper-class="tool-tip"  class="item" effect="dark" :content="item.standardList || '暂无要素'" placement="top" v-for="(item, index) in facilityList" :key="index">
            <el-tag>{{item.facilityTypeName}}({{item.facilityCount}})</el-tag>
        </el-tooltip> -->
        <!-- <el-tag v-for="(item, index) in detail.facilityList" :key="index">{{item.facilityTypeName}}({{item.facilityCount}})</el-tag> -->
      </div>
      <div class="lineBlock" v-if="detail.elementList">
        <p class="name">巡查对象：</p>
        <p class="pointDetail" @click="isShowObject = true">已选({{elementList.length}})</p>
        <!-- <div class="line-time"> -->
        <!-- <el-tooltip popper-class="tool-tip"  class="item" effect="dark" v-for="(item, index) in elementList" :content="item.standardList ||'暂无要素'" placement="top"  :key="index">
            <el-tag>{{item.elementName}}</el-tag>
        </el-tooltip> -->
            <!-- <el-tag v-for="(item, index) in detail.elementList" :key="index">{{item.elementName}}</el-tag> -->
        <!-- </div> -->
      </div>
    </div>
    <div class="line"></div>
    <div class="title-block">任务记录</div>
    <div>
        <el-form inline ref="searchForm" :model="searchForm">
            <el-form-item prop="date">
                <el-date-picker
                    v-model="date"
                    @change="changeDate"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    start-placeholder="任务开始日期"
                    end-placeholder="任务结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item prop="taskStatus">
                <el-select v-model="searchForm.taskStatus" placeholder="请选择完成情况" filterable clearable>
                    <el-option v-for="(item, index) in taskStatusList" :label="item" :value="filtIndex(index)" :key="index"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="loadTask">查询</el-button>
            </el-form-item>
        </el-form>
        <custom-export v-show="$ButtonShow('taskExport')" type="2" :params="searchForm" class="mb20"></custom-export>
        <el-table class="global-table" v-loading="loading" :data="taskList">
            <el-table-column label="任务名称" show-overflow-tooltip min-width="120" prop="taskName"></el-table-column>
            <el-table-column label="开始日期" show-overflow-tooltip min-width="100" prop="taskStartTime"></el-table-column>
            <el-table-column label="结束日期" show-overflow-tooltip min-width="100" prop="taskEndTime"></el-table-column>
            <el-table-column label="任务执行人" show-overflow-tooltip prop="executeUserName" min-width="100"></el-table-column>
            <el-table-column label="完成情况" show-overflow-tooltip prop="taskStatus" min-width="100">
                <template v-slot="{ row }">
                    <span
                    v-if="[1, 2, 4, 5, 6].includes(row.taskStatus)"
                    :class="{ 'c-dark': [1, 2].includes(row.taskStatus),
                    'c-yellow': row.taskStatus === 3,
                    'c-green': row.taskStatus === 4,
                    'c-red': row.taskStatus === 5,
                    'c-gray': row.taskStatus === 6 }">
                    {{ filtVal(row.taskStatus) }}
                    </span>
                    <!-- <span
                    v-if="[1, 2, 4, 5, 6].includes(row.taskStatus)"
                    :class="{ 'c-dark': [1, 2].includes(row.taskStatus),
                    'c-yellow': row.taskStatus === 3,
                    'c-green': row.taskStatus === 4,
                    'c-red': row.taskStatus === 5,
                    'c-gray': row.taskStatus === 6 }">
                    {{ taskStatusList[row.taskStatus -1]}}
                    </span> -->
                </template>
            </el-table-column>
            <el-table-column label="问题数量" show-overflow-tooltip min-width="100">
                <template v-slot="{row}">{{row.yclIssueNum+'/' + row.issueNum}}</template>
            </el-table-column>
            <el-table-column label="操作" min-width="100" fixed="right">
            <template v-slot="{ row }">
                <el-button type="text" @click="onDetail(row.taskId)">查看</el-button>
                <el-button type="text" @click="ontransferTask(row)" v-if="(row.taskStatus === 1 && userId === row.executeUserId) || (row.taskStatus === 1 && hasTransfer)">转办</el-button>
                <el-button type="text" @click="onDelete(row.taskId)" v-if="row.taskStatus === 1">删除</el-button>
            </template>
        </el-table-column>
        </el-table>
        <global-pagination
        @size-change="loadTask"
        @current-change="loadTask"
        :current-page.sync="page"
        :page-size.sync="size"
        :total="total">
        </global-pagination>
    </div>
    <!-- -------------------- -->
    <LineSelectDetail
      v-if="detail.patrolMode === 1"
      :selectLineShow.sync="selectLineShow"
      :projectId="detail.projectId"
      :elementList="detail.elementList"
      :lineList="detail.lineList"
      :planeRegisterList="detail.planeRegisterList">
    </LineSelectDetail>
    <PointSelectDetail
      v-else
      :selectPointShow.sync="selectPointShow"
      :projectId="detail.projectId"
      :facilityList="detail.facilityList || []">
    </PointSelectDetail>
    <transfer-task :isShowTransfer.sync="isShowTransfer" :patrolUnit="patrolUnit" :executeUserId="executeUserId" :safeManageId="safeManageId" :taskId="taskId" :projectId="projectId" @loadTask="loadTask"></transfer-task>
    <PatrolObject :isShowObject.sync="isShowObject" :elementList="elementList"></PatrolObject>
  </el-card>
</template>

<script>
import LineSelectDetail from './LineSelectDetail'
import PointSelectDetail from './PointSelectDetail'
import TransferTask from './TransferTask'
import PatrolObject from './PatrolObject'
export default {
  name: 'PlanDetail',
  components: { LineSelectDetail, PointSelectDetail, TransferTask, PatrolObject },
  //  taskStatus (1未巡查2巡查中3未完成4已完成5已逾期6已取消)
  data () {
    return {
      isShowTransfer: false,
      isShowObject: false,
      checkLength: 0,
      executeUserId: '',
      safeManageId: '',
      projectId: '',
      taskId: '',
      typeIndex: 0,
      elementList: [
        {
          standardList: ''
        }
      ], // 巡查对象
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      patrolUnit: 1,
      date: [],
      searchForm: {
        planId: '',
        planName: '',
        projectId: '',
        safeManageId: '',
        patrolMode: '',
        planType: '',
        taskStatus: '',
        startTime: '',
        endTime: ''
      },
      taskList: [],
      taskStatusList: ['待执行', '执行中', '已完成', '已逾期'],
      activeNames: ['1', '2'],
      patrolModeList: ['线路', '点位'],
      planTypeList: ['日巡查', '周巡查', '月巡查'],
      patrolUnitList: ['管理抽查', '项目自查'],
      generateTypeList: ['次日生效', '立即生效'],
      detail: {},
      selectLineShow: false,
      selectPointShow: false
    }
  },
  computed: {
    planId () {
      return this.$route.params.id
    },
    dateName () {
      return this.detail.planType ? ['日', '周', '月'][this.detail.planType - 1] : ''
    },
    userId () {
      return this.$store.state.userInfo.userId
    },
    hasTransfer () {
      return this.$store.state.buttonList.some(item => item.butCode === 'transfer')
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.searchForm.planId = this.planId
      this.loadData()
      this.loadTask()
    },
    changeDate (date) {
      this.searchForm.startTime = (date && date[0]) || ''
      this.searchForm.endTime = (date && date[1]) || ''
    },
    loadData () {
      this.$axios.get(this.$apis.patrol.selectPlaneByPlaneId, {
        planId: this.planId
      }).then(res => {
        this.detail = res || {}
        this.handleListData(res)
        this.patrolUnit = res.patrolUnit
        this.facilityList = res.facilityList || []
        // console.log('巡查方(1公司检查2合作单位自查):', this.patrolUnit)
      })
    },
    listHandler (list) {
      return list.reduce((prev, cur) => prev + cur.facilityCount, 0)
    },
    filtIndex (index) {
      let option = 0
      switch (index) {
        case 2:
          option = 4
          break
        case 3:
          option = 5
          break
        default:
          option = index + 1
          break
      }
      return option
    },
    filtVal (status) {
      let option = ''
      switch (status) {
        case 4:
          option = this.taskStatusList[2]
          break
        case 5:
          option = this.taskStatusList[3]
          break
        default:
          option = this.taskStatusList[status - 1]
          break
      }
      return option
    },
    // 处理要素内容
    handleListData (detail) {
      if (detail.elementList) {
        this.elementList = detail.elementList
        this.elementList.forEach((item, index) => {
          if (item.standardList) {
            item.standardList = item.standardList.map((x, i) => (i + 1) + '.' + x.standardContent).join(' ')
          }
        })
        // console.log('this.elementList:', this.elementList)
      } else if (detail.facilityList) {
        this.elementList = detail.facilityList
        this.elementList.forEach((item, index) => {
          if (item.standardList) {
            item.standardList = item.standardList.map((x, i) => (i + 1) + '.' + x.standardContent).join(' ')
          }
        })
        // console.log('this.elementList1:', this.elementList)
      }
    },
    loadTask () {
      this.loading = true
      // const dateInfo = this.date && this.date.length === 2
      //   ? {
      //       startTime: this.date[0],
      //       endTime: this.date[1]
      //     }
      //   : {}
      this.$axios.get(this.$apis.patrol.selectTaskByPage, {
        page: this.page,
        size: this.size,
        ...this.searchForm
      }).then(res => {
        // console.log('表格数据', res.list)
        this.taskList = res.list || []
        this.total = res.total
      }).finally(() => {
        this.loading = false
      })
    },
    selectPatrolType (type) {
      // 1 为线路  2为点位
      if (type === 1) {
        this.type = 1
        this.selectLineShow = true
      } else {
        this.type = 2
        this.selectPointShow = true
      }
    },
    onDetail (taskId) {
      this.$router.push({ name: 'patrolTaskDetail', params: { taskId } })
    },
    ontransferTask (row) {
      this.isShowTransfer = true
      this.taskId = row.taskId
      this.executeUserId = row.executeUserId
      this.safeManageId = row.safeManageId
      this.projectId = row.projectId
    },
    onDelete (taskId) {
      this.$confirm('确认删除该巡查任务吗？，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$apis.patrol.deleteTask, { taskId }).then(() => {
          this.loadTask()
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.title-block {
    width: 100%;
    height: 14px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 14px;
    margin-bottom: 20px;
  }
  .line {
    border-top: 1px solid #EAEEF5;
    margin-bottom: 20px;
  }
  .lineBlock {
    display: flex;
    margin-bottom: 20px;
    p {
      width: auto;
      height: auto;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
      &.pointDetail {
        text-decoration: underline;
        color: #1AD1B0;
        cursor: pointer;
      }
      &.name {
        width: 120px;
        flex-shrink: 0;
        text-align: right;
        color: #333333;
      }
    }
  }
  .itemBlock {
    display: flex;
    flex-wrap: wrap;
    .lineBlock {
      display: flex;
      flex-wrap: wrap;
      width: 350px;
      margin-right: 40px;
      .patrol-type {
        color: #1AD1B0;
        cursor: pointer;
        text-decoration: underline solid #1AD1B0;
      }
      .line-time {
        flex: 1;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
        // display: flex;
        // flex-wrap: wrap;
      }
    }
  }
  ::v-deep .el-tag {
    margin-right: 5px;
    margin-bottom: 5px;
  }
</style>
<style>
  .tool-tip {
    max-width: 400px;
  }
</style>
